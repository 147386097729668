'use client'
import CustomNotFound from "@/components/UI/common/notFound/CustomNotFound";
import { useEffect } from "react";

type Props = {
    error: Error & { digest?: string }
    reset: () => void
}
function GlobalError({ error, reset }: Props) {
    useEffect(() => {
    }, [error])

    return (
        <html>
            <body>
                <CustomNotFound msg={"هناك عطل في الموقع ونعمل حاليا علي إصلاحه"} />
            </body>
        </html>
    )
}

export default GlobalError;